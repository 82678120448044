/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import third party library styles here.
/* ----------------------------------------------------------------------------------------------------- */
/* Perfect scrollbar */
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
/* Quill */
@import "~quill/dist/quill.snow.css";
/* angular-cli file: src/styles.css */
@import "../../node_modules/angular-calendar/css/angular-calendar.css";
/* Table columns with text will align on left */
.table-text {
  text-align: left !important;
}

.mat-mdc-form-field-required-marker {
  display: none !important;
}

.my-snack-bar button {
  background-color: gray;
  color: white;
}